import React from "react";
import firebase from "firebase";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Switch,
  Slider,
  Container,
  FormControlLabel,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Snackbar,
} from "@material-ui/core";

import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import Paper from "@material-ui/core/Paper";

import MuiAlert from "@material-ui/lab/Alert";

import "./App.css";

const firebaseConfig = {
  apiKey: "AIzaSyA5oPInGwPeOPEUO8qYFnYc2CwG4F0soGU",
  authDomain: "teleconsultacetrus.firebaseapp.com",
  databaseURL: "https://teleconsultacetrus.firebaseio.com",
  projectId: "teleconsultacetrus",
  storageBucket: "teleconsultacetrus.appspot.com",
  messagingSenderId: "340629313983",
  appId: "1:340629313983:web:509040a77ba67aa8bbe90a",
};

firebase.initializeApp(firebaseConfig);

const useStyles = (theme) => ({
  root: {
    paddingTop: "5%",
  },
  videoFrame: {
    margin: "auto",
    width: "100%",
    height: "600px",
    border: "none",
  },
  formControl: {
    marginTop: 24,
    marginBottom: 24,
    minWidth: 120,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold,
  },
  headingOfPage: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightBold,
    textAlign: "center",
  },
  table: {
    maxWidth: "100%",
  },
});

class App extends React.Component {
  database = firebase.database();

  constructor() {
    super();

    this.state = {
      firstExecute: true,

      autoExposureLock: false,
      cameraId: false,
      startTransmission: false,
      videoPlayed: false,
      isoSelected: "auto",
      isoAlloweds: [],

      minExposureTime: 0,
      maxExposureTime: 0,
      exposureTime: 0,

      minIso: 0,
      maxIso: 0,
      iso: 0,

      allowTransmission: false,

      showMessage: false,
      message: "",
      cameraCharacteristics: [],
      deviceModel: "",
      deviceManufacturer: "",
      whiteBalancePresets: [],
      whiteBalance: "",
      brightness: 0,

      cameraList: [],
      controlAvailableModes: [],
      requestAvailableCapabilities: [],
      infoSupportedHardwareLevel: "",
    };
  }

  componentDidMount() {
    this.getFirebaseData();
    this.videoListener();
  }

  videoListener() {
    let self = this;
    window.addEventListener(
      "message",
      function (event) {
        if (event.origin !== "https://dist.bambuser.net") {
          return;
        }
        var videoEvent = event.data.videoEvent;
        if (videoEvent) {
          if (videoEvent.name === "play") {
            self.setState((state) => ({
              ...state,
              videoPlayed: true,
            }));
          }
        }
      },
      false
    );
  }

  getFirebaseData() {
    this.database.ref("exposureTime").once("value", (snapshot) => {
      this.setState((state) => {
        return {
          ...state,
          value: snapshot.val(),
        };
      });
    });

    this.database.ref("cameraList").once("value", (snapshot) => {
      this.setState((state) => {
        return {
          ...state,
          cameraList: snapshot.val(),
        };
      });
    });

    this.database.ref("controlAvailableModes").once("value", (snapshot) => {
      this.setState((state) => {
        return {
          ...state,
          controlAvailableModes: snapshot.val(),
        };
      });
    });

    this.database
      .ref("requestAvailableCapabilities")
      .once("value", (snapshot) => {
        this.setState((state) => {
          return {
            ...state,
            requestAvailableCapabilities: snapshot.val(),
          };
        });
      });

    this.database
      .ref("infoSupportedHardwareLevel")
      .once("value", (snapshot) => {
        this.setState((state) => {
          return {
            ...state,
            infoSupportedHardwareLevel: snapshot.val(),
          };
        });
      });

    this.database.ref("autoExposureLock").once("value", (snapshot) => {
      this.setState((state) => {
        return {
          ...state,
          autoExposureLock: snapshot.val(),
        };
      });
    });

    this.database.ref("cameraId").once("value", (snapshot) => {
      this.setState((state) => {
        return {
          ...state,
          cameraId: snapshot.val() === 0,
        };
      });
    });

    this.database.ref("startTransmission").once("value", (snapshot) => {
      let localFirstExecute = false;
      let localShowMessage = false;
      let localMessage = "";
      let localStartTransmission = false;

      if (snapshot.val() === true && this.state.firstExecute === true) {
        localShowMessage = true;
        localMessage = "Já existe uma transmissão em andamento. Aperte o play.";
      }

      localStartTransmission = snapshot.val();

      this.setState((state) => ({
        ...state,
        showMessage: localShowMessage,
        message: localMessage,
        startTransmission: localStartTransmission,
        firstExecute: localFirstExecute,
      }));
    });

    this.database.ref("allowTransmission").on("value", (snapshot) => {
      this.setState((state) => {
        return {
          ...state,
          allowTransmission: snapshot.val() || false,
        };
      });
    });

    this.database.ref("isoAlloweds").on("value", (snapshot) => {
      this.setState((state) => {
        return {
          ...state,
          isoAlloweds: snapshot.val() || [],
        };
      });
    });

    this.database.ref("isoSelected").on("value", (snapshot) => {
      this.setState((state) => {
        return {
          ...state,
          isoSelected: snapshot.val(),
        };
      });
    });

    this.database.ref("minExposureTime").on("value", (snapshot) => {
      this.setState((state) => {
        return {
          ...state,
          minExposureTime: snapshot.val(),
        };
      });
    });

    this.database.ref("maxExposureTime").on("value", (snapshot) => {
      this.setState((state) => {
        return {
          ...state,
          maxExposureTime: snapshot.val(),
        };
      });
    });

    this.database.ref("minIso").on("value", (snapshot) => {
      this.setState((state) => {
        return {
          ...state,
          minIso: snapshot.val(),
        };
      });
    });

    this.database.ref("maxIso").on("value", (snapshot) => {
      this.setState((state) => {
        return {
          ...state,
          maxIso: snapshot.val(),
        };
      });
    });

    this.database.ref("cameraCharacteristics").on("value", (snapshot) => {
      this.setState((state) => {
        return {
          ...state,
          cameraCharacteristics: snapshot.val(),
        };
      });
    });

    this.database.ref("deviceManufacturer").on("value", (snapshot) => {
      this.setState((state) => {
        return {
          ...state,
          deviceManufacturer: snapshot.val(),
        };
      });
    });

    this.database.ref("deviceModel").on("value", (snapshot) => {
      this.setState((state) => {
        return {
          ...state,
          deviceModel: snapshot.val(),
        };
      });
    });

    this.database.ref("whiteBalancePresets").once("value", (snapshot) => {
      this.setState((state) => {
        return {
          ...state,
          whiteBalancePresets: snapshot.val(),
        };
      });
    });

    this.database.ref("whiteBalance").once("value", (snapshot) => {
      this.setState((state) => {
        return {
          ...state,
          whiteBalance: snapshot.val(),
        };
      });
    });

    this.database.ref("brightness").on("value", (snapshot) => {
      this.setState((state) => {
        return {
          ...state,
          brightness: snapshot.val(),
        };
      });
    });
  }

  handleChangeIso = (event, newValue) => {
    this.setState((state) => ({ ...state, iso: newValue }));
    this.database.ref().update({ iso: Math.ceil(newValue) });
  };

  handleChangeWb = (event) => {
    this.setState((state) => ({ ...state, whiteBalance: event.target.value }));
    this.database.ref().update({ whiteBalance: event.target.value });
  };

  handleChangeBrightness = (event, newValue) => {
    this.database.ref().update({ brightness: newValue });
    this.setState((state) => ({ ...state, brightness: newValue }));

    let iframe = document.querySelectorAll("iframe")[0];
    if (iframe) {
      iframe.style.filter = `brightness(${(newValue / 100).toFixed(2)})`;
    }
  };

  handleChange = (event, newValue) => {
    this.setState((state) => ({ ...state, exposureTime: newValue }));
    this.database.ref().update({ exposureTime: Math.ceil(newValue) });
  };

  handleToggle = () => {
    this.setState((state) => ({
      ...state,
      autoExposureLock: !this.state.autoExposureLock,
    }));
    this.database
      .ref()
      .update({ autoExposureLock: !this.state.autoExposureLock });
  };

  handleStartTransmission = () => {
    this.setState((state) => ({
      ...state,
      startTransmission: !this.state.startTransmission,
      showMessage: true,
      message: this.state.startTransmission
        ? "Você finalizou a transmissão."
        : "Por favor aperte o play no vídeo para visualizar a transmissão. Certifique-se de que o aplicativo esteja aberto para que a transmissão seja realizada.",
    }));
    this.database
      .ref()
      .update({ startTransmission: !this.state.startTransmission });

    setTimeout(() => {
      document.getElementsByTagName(
        "iframe"
      )[0].src = document.getElementsByTagName("iframe")[0].src;
    }, 2000);
  };

  handleCamera = () => {
    this.setState((state) => ({
      ...state,
      cameraId: !this.state.cameraId,
    }));
    this.database.ref().update({ cameraId: this.state.cameraId ? 1 : 0 });
  };

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState((state) => ({ ...state, showMessage: false }));
  };

  render() {
    const { classes } = this.props;

    return (
      <Container className={classes.root}>
        <Snackbar
          open={this.state.showMessage}
          onClose={this.handleClose}
          autoHideDuration={5000}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            severity="info"
            onClose={this.handleClose}
          >
            {this.state.message}
          </MuiAlert>
        </Snackbar>

        <Grid container spacing={2}>
          <Grid item xs={12} lg={12}>
            <Typography className={classes.headingOfPage}>
              CetrusLab - Aplicação de controle da câmera de dispositivos
              celulares
            </Typography>
          </Grid>

          <Grid item xs={12} lg={4}>
            <ExpansionPanel defaultExpanded={false}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3a-content"
                id="panel3a-header"
              >
                <Typography className={classes.heading}>
                  Características do Aparelho
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">Característica</TableCell>
                        <TableCell align="center">Valor</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>Fabricante</TableCell>
                        <TableCell>{this.state.deviceManufacturer}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>Modelo</TableCell>
                        <TableCell>{this.state.deviceModel}</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>Nível de acesso ao hardware</TableCell>
                        <TableCell>
                          {this.state.infoSupportedHardwareLevel}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>
                          Capacidade de requisição disponível
                        </TableCell>
                        <TableCell>
                          {this.state.requestAvailableCapabilities.join(", ")}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>Modos de controle disponíveis</TableCell>
                        <TableCell>
                          {this.state.controlAvailableModes.join(", ")}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Grid>

          <Grid item xs={12} lg={4}>
            <ExpansionPanel defaultExpanded={false}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4a-content"
                id="panel4a-header"
              >
                <Typography className={classes.heading}>
                  Lista de Câmeras
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">ID</TableCell>
                        <TableCell align="center">Frontal?</TableCell>
                        <TableCell align="center">Controle da ISO?</TableCell>
                        <TableCell align="center">
                          Controle do tempo de exposição?
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.cameraList.map((row) => (
                        <TableRow key={row}>
                          <TableCell align="center">{row.id}</TableCell>
                          <TableCell align="center">
                            {row.front ? "Sim" : "Não"}
                          </TableCell>
                          <TableCell align="center">
                            {row.allowIsoAdjust ? "Sim" : "Não"}
                          </TableCell>
                          <TableCell align="center">
                            {row.allowExposureTimeAdjust ? "Sim" : "Não"}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Grid>

          <Grid item xs={12} lg={4}>
            {this.state.cameraCharacteristics.length >= 0 && (
              <ExpansionPanel>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>
                    Características da câmera
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">Chave</TableCell>
                          <TableCell align="left">Valor</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.cameraCharacteristics.map((row) => (
                          <TableRow key={row}>
                            <TableCell>{row.split("=")[0]}</TableCell>
                            <TableCell>{row.split("=")[1].trim()}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            )}
          </Grid>

          <Grid item xs={12} lg={12}>
            <ExpansionPanel defaultExpanded={true}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography className={classes.heading}>
                  Controle da câmera
                </Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">Atributo</TableCell>
                        <TableCell align="center">Valor</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>Tempo de exposição</TableCell>
                        <TableCell>
                          <Slider
                            min={this.state.minExposureTime}
                            max={this.state.maxExposureTime}
                            disabled={
                              this.state.minExposureTime ==
                              this.state.maxExposureTime
                            }
                            step={1}
                            value={this.state.exposureTime}
                            onChange={this.handleChange}
                            aria-labelledby="continuous-slider"
                          />
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>ISO</TableCell>
                        <TableCell>
                          <Slider
                            min={this.state.minIso}
                            max={this.state.maxIso}
                            disabled={this.state.minIso == this.state.maxIso}
                            step={1}
                            value={this.state.iso}
                            onChange={this.handleChangeIso}
                            aria-labelledby="continuous-slider"
                          />
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>Brilho</TableCell>
                        <TableCell>
                          <Slider
                            min={0}
                            max={100}
                            step={1}
                            value={this.state.brightness}
                            onChange={this.handleChangeBrightness}
                            aria-labelledby="continuous-slider"
                          />
                        </TableCell>
                      </TableRow>

                      {/* <TableRow>
                        <TableCell>Auto exposição</TableCell>
                        <TableCell>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={this.state.autoExposureLock}
                                onChange={this.handleToggle}
                                name="autoExposureLock"
                                color="primary"
                                label="Primary"
                              />
                            }
                            label=""
                          />
                        </TableCell>
                      </TableRow> */}

                      {/* <TableRow>
                        <TableCell>
                          Seletor de câmera (
                          {!this.state.cameraId ? "Frontal" : "Traseira"})
                        </TableCell>
                        <TableCell>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={this.state.cameraId}
                                onChange={this.handleCamera}
                                name="cameraId"
                                color="primary"
                                label="Primary"
                              />
                            }
                            label={""}
                          />
                        </TableCell>
                      </TableRow> */}

                      {this.state.allowTransmission && (
                        <TableRow>
                          <TableCell>Transmissão</TableCell>
                          <TableCell>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={this.state.startTransmission}
                                  onChange={this.handleStartTransmission}
                                  name="startTransmission"
                                  color="primary"
                                  label="Primary"
                                />
                              }
                              label=""
                            />
                          </TableCell>
                        </TableRow>
                      )}

                      {/* <TableRow>
                        <TableCell>ISO</TableCell>
                        <TableCell>
                          {this.state.isoAlloweds.length > 0 && (
                            <FormControl className={classes.formControl}>
                              <InputLabel>ISO</InputLabel>
                              <Select
                                autoWidth
                                value={this.state.isoSelected}
                                onChange={this.handleChangeIso}
                              >
                                {this.state.isoAlloweds.map((iso, index) => (
                                  <MenuItem key={index} value={iso}>
                                    {iso.replace("ISO", "").replace("_", "")}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          )}
                        </TableCell>
                      </TableRow> */}

                      {/* <TableRow>
                        <TableCell>Balanço de branco</TableCell>
                        <TableCell>
                          {this.state.whiteBalancePresets.length > 0 && (
                            <FormControl className={classes.formControl}>
                              <InputLabel>WB</InputLabel>
                              <Select
                                autoWidth
                                value={this.state.whiteBalance}
                                onChange={this.handleChangeWb}
                              >
                                {this.state.whiteBalancePresets.map(
                                  (wb, index) => (
                                    <MenuItem key={index} value={wb}>
                                      {wb}
                                    </MenuItem>
                                  )
                                )}
                              </Select>
                            </FormControl>
                          )}
                        </TableCell>
                      </TableRow> */}
                    </TableBody>
                  </Table>
                </TableContainer>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Grid>
          {this.state.allowTransmission && (
            <Grid item xs={12} lg={12}>
              <iframe
                title="RTMP"
                src="https://dist.bambuser.net/player/?resourceUri=https%3A%2F%2Fcdn.bambuser.net%2Fgroups%2F94411%2Fbroadcasts%3Fby_authors%3D%26title_contains%3D%26has_any_tags%3D%26has_all_tags%3D%26da_id%3Dda2e902d-c86f-f2c9-0191-5a06443aaabd%26da_timestamp%3D1593034747%26da_signature_method%3DHMAC-SHA256%26da_ttl%3D0%26da_static%3D1%26da_signature%3D103699ea64c1dc6f981fe663cba4fa8809ce2a86f94aa6619e648b08f8c247e6"
                className={classes.videoFrame}
                allowFullScreen
                autoPlay
              ></iframe>
            </Grid>
          )}
        </Grid>
      </Container>
    );
  }
}

export default withStyles(useStyles, { withTheme: true })(App);
